import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, tap } from 'rxjs';
import { environment } from 'environments/environment';
import { IGroup, CreateGroupRequest, UpdateGroupRequest } from 'app/models/group.type';
import { RoleIdType } from 'app/models/role.constant';
import { RoleService } from './role.service';
import { BaseEntityService, CrudEndpoints } from '@gci/components/base-page/base-entity.service';
import { Role } from 'app/models/role.type';
import { PAGESIZE } from 'app/models/constants/table.constants';

@Injectable({ providedIn: 'root' })
export class GroupService extends BaseEntityService<IGroup> {

    private _roles: Role[] = [];
    constructor(
        httpClient: HttpClient,
        private roleService: RoleService
    ) {
        const endpoints: CrudEndpoints = {
            list: 'group-service/group',
            create: 'group-service/group',
            update: (id: string) => `group-service/group/${id}`,
            delete: (id: string) => `group-service/group/${id}`,
            join: 'group-service/group/member/join',
            leave: 'group-service/group/member/leave',
            memberJoin: 'group-service/member/join'
        };

        super(httpClient, environment.apiBaseUrl, endpoints, 'GroupService');

        this.roleService.roles$.subscribe(res => {
            this._roles = res;
        });
    }

    // Override the generic accessors with group-specific names
    get allGroup$(): Observable<IGroup[]> {
        return this._allItems.asObservable();
    }

    set allGroup(data: IGroup[]) {
        this._allItems.next(data);
    }

    get activeGroup$(): Observable<IGroup | null> {
        return this._activeItem.asObservable();
    }

    get activeGroupId(): string {
        return this._activeItem.getValue()?.id || '';
    }

    set activeGroup(id: string) {
        const allItems = this._allItems.getValue();
        const foundItem = allItems.find(item => item.id === id);

        this._activeItem.next(foundItem || null);
    }

    // Group-specific methods
    getGroup(forceRefresh = false, search: string = '', page: string = '1', limit: string = PAGESIZE, sortBy: string = 'created_at', sort: 'desc' | 'asc' = 'asc'): Observable<IGroup[]> {
        const queryParams : Record<string, string> = {
            search: search,
            page: page,
            limit: limit,
            sort: sort,
            sortby: sortBy,
        };
        return this.getListNewFormat(forceRefresh, queryParams);
    }
    
    joinGroup(groupId: string, userId: string, tenant_id: string, role?: RoleIdType): Observable<IGroup> {
        this._loading.next(true);
        const defaultRole = this._roles.find(el => el.name === 'read-only');
        const customRole = role && this._roles.find(el => el.id === role);
        const body = {
            user_id: userId,
            group_id: groupId,
            role_name: role ? customRole?.name : defaultRole?.name,
            role_id: role ? customRole?.id : defaultRole?.id,
            tenant_id: tenant_id
        };
        const endpoint = this.endpoints['join'];

        // Use the base create method since it handles the state updates we need
        return this.httpClient.post<{ message: IGroup }>(`${this.baseUrl}${endpoint}`, body).pipe(
            map(response => response.message),
            tap(newGroup => {
                const currentItems = this._allItems.getValue();
                this._allItems.next([...currentItems, newGroup]);
                this._activeItem.next(newGroup);
                this._loading.next(false);
            }),
            catchError(error => {
                this._loading.next(false);
                return this.handleOperationError('joinGroup', endpoint, error);
            })
        );
    }

    memberJoinGroup(groupId: string[], userId: string, tenant_id: string, role?: RoleIdType, forOtherUser?: boolean): Observable<IGroup[]> {
        this._loading.next(true);
        const defaultRole = this._roles.find(el => el.name === 'read-only');
        const customRole = role && this._roles.find(el => el.id === role);
        const body = {
            user_id: userId,
            all_group_id: groupId,
            role_name: role ? customRole?.name : defaultRole?.name,
            role_id: role ? customRole?.id : defaultRole?.id,
            tenant_id: tenant_id
        };
        const endpoint = this.endpoints['memberJoin'];

        // Use the base create method since it handles the state updates we need
        return this.httpClient.post<{ message: {data: IGroup[]} }>(`${this.baseUrl}${endpoint}`, body).pipe(
            map(response => response.message.data),
            tap(newGroup => {
                if (!forOtherUser) {
                    const currentItems = this._allItems.getValue();
            
                    // Filter out items that already exist in currentItems based on their id
                    const mergedItems = [...currentItems];
                    newGroup.forEach(item => {
                        if (!currentItems.some(existingItem => existingItem.id === item.id)) {
                            mergedItems.push(item);
                        }
                    });
                
                    this._allItems.next(mergedItems);
                    this._activeItem.next(newGroup[0]);
                }
                this._loading.next(false);
            }),
            catchError(error => {
                this._loading.next(false);
                return this.handleOperationError('joinGroup', endpoint, error);
            })
        );
    }

    leaveGroup(userId: string): Observable<any> {
        const body = {
            group_id: this.activeGroupId
        };
        const endpoint = this.endpoints['leave'];

        return this.httpClient.request<{ message: any }>("DELETE", `${this.baseUrl}${endpoint}`, { body }).pipe(
            map(response => response.message),
            tap(deletedGroup => {
                const currentItems = this._allItems.getValue();
                const filteredItems = currentItems.filter(item => item.id !== deletedGroup.group_id);
                this._allItems.next(filteredItems);
                
                if (this._activeItem.getValue()?.id === deletedGroup.group_id) {
                    this._activeItem.next(filteredItems[0] || null);
                }
            }),
            catchError(error => {
                this._loading.next(false);
                return this.handleOperationError('leaveGroup', endpoint, error);
            })
        );
    }

    createGroup(request: CreateGroupRequest): Observable<IGroup> {
        const newGroup = {
            alias: request.alias,
            solution: request.solution,
            description: request.description,
            tenant_id: request.tenant_id,
            notes: request.notes,
        };
        
        return this.create(newGroup,
            (newGroup: IGroup) => ({
                ...newGroup,
                role_name: 'owner'
            })
        );
    }

    updateGroup(request: UpdateGroupRequest): Observable<IGroup> {
        return this.update(
            this.activeGroupId, 
            request,             
            (updatedGroup: IGroup) => ({
                ...updatedGroup,
                role_name: 'owner'
            })
        );
    }

    deleteGroup(id?: string): Observable<IGroup> {
        return this.delete(id ? id :this.activeGroupId);
    }    

    // Override clearState to use group-specific naming
    clearGroup(): void {
        this.clearState();
    }
}