import { PaginationMetaData } from "app/models/event.type";

export interface KeyTable {
    [key: string]: string[]
}

export const TableHeader: KeyTable = {
    tenant: ['Tenant name', 'Groups', 'Members', 'Devices'],
    groups: ['Name', 'Tenant', 'Devices'],
    users: ['Name', 'Email', 'Phone', 'Tenant', 'Join At'],
    devices: ['SN', 'Name', 'Registered', 'Warranty', 'Status'],
    'income': ['ID Transaction', 'Tenant', 'Name', 'Cost'],
    'devices-offline': ['SN', 'Tenant', 'Name'],
}

export const TableKey: KeyTable = {
    tenant: ['name', 'group', 'members', 'devices'],
    groups: ['name', 'tenant', 'devices'],
    users: ['name', 'email', 'phone_number', 'tenant', 'join_at'],
    devices: ['id', 'name', 'registered_at', 'warranty_until', 'status_name'],
    'income': ['id', 'tenant', 'name', 'cost'],
    'devices-offline': ['id', 'tenant', 'name']
}

export const TableAdminHeader: KeyTable = {
    groups: ['Name', 'Solution', 'Notes'],
    devices: ['SN', 'Name', 'Registered', 'Warranty', 'Status', 'Description', 'Product'],
    users: ['Name', 'Email', 'Phone', 'Join At', 'Last Online'],
    'ticket': ['SN', 'Name', 'Problem', 'Created At'],
    'income': ['SN', 'No Meter', 'Customer', 'Total Purchase', 'Total kWh'],
    energy: ['SN', 'No Meter', 'Customer', 'Total kWh', 'Estimated costs'],
    'detail_transaction': ['ID Transaction', 'Purchase Date', 'Cost', 'Total kWh', 'Payment Type', 'Status'],
    'detail_energy': ['Date', 'Total Consuption', 'Estimated costs'],
    'devices-trouble': ['SN', 'Name', 'Status', 'Description']
}

export const TableAdminKey: KeyTable = {
    groups: ['alias', 'solution_name', 'notes'],
    devices: ['id', 'name', 'created_at_date', 'warranty', 'status_converter', 'description', 'product_name'],
    users: ['name', 'email', 'phone_number', 'created_at_date', 'last_online_date'],
    'ticket': ['device_id', 'name', 'problem', 'created_at'],
    'income': ['id', 'meter_id', 'description', 'total_purchase', 'energy_consumption'],
    energy: ['id', 'meter_id', 'description', 'energy_consumption', 'estimation_cost'],
    'detail_transaction': ['transaction_id', 'date', 'cost', 'energy', 'payment_method', 'status'],
    'detail_energy': ['date', 'energy_consumption', 'estimation_cost'],
    'devices-trouble': ['id', 'name', 'status_converter', 'description']
}

export const PAGESIZE = '5';

export const PaginationDefault: PaginationMetaData = {
    total_records: 10,
    page_size: 10,
    current_page: 1,
    total_pages: 2,
    has_next_page: true,
    has_previous_page: false
}

export interface IEnergy {
    id: string; date: string; energy: number; device_id: string; no_meter: string;
}

export interface ITransaction {
    id: string; date: string; cost: number; energy: number; device_id: string; payment_type: string;
}

export const DATA_ENERGY: IEnergy[] = [
    {
        id: "1",
        date: new Date().toISOString(),
        energy: 120.5,
        device_id: "SP21e831cd6a47c",
        no_meter: "86083391853",
    },
    {
        id: "2",
        date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
        energy: 115.2,
        device_id: "SP21e831cd6a47c",
        no_meter: "86083391853",
    },
    {
        id: "3",
        date: new Date(new Date().setDate(new Date().getDate() - 2)).toISOString(),
        energy: 130.8,
        device_id: "SP21e831cd6a47c",
        no_meter: "86083391853",
    },
    {
        id: "4",
        date: new Date(new Date().setDate(new Date().getDate() - 3)).toISOString(),
        energy: 110.3,
        device_id: "SP21e831cd6a47c",
        no_meter: "86083391853",
    },
    {
        id: "5",
        date: new Date(new Date().setDate(new Date().getDate() - 4)).toISOString(),
        energy: 125.6,
        device_id: "SP21e831cd6a47c",
        no_meter: "86083391853",
    }
];

export const DATA_TRANSACTION: ITransaction[] = [
    {
        id: "1",
        date: "2025-01-05T00:00:00.000Z",
        cost: 50000,
        energy: 120.5,
        device_id: "SP21e831cd6a47c",
        payment_type: "Credit Card"
    },
    {
        id: "2",
        date: "2024-12-04T00:00:00.000Z",
        cost: 100000,
        energy: 115.2,
        device_id: "SP21e831cd6a47c",
        payment_type: "Bank Transfer"
    },
    {
        id: "3",
        date: "2024-11-03T00:00:00.000Z",
        cost: 75000,
        energy: 130.8,
        device_id: "SP21e831cd6a47c",
        payment_type: "E-Wallet"
    },
    {
        id: "4",
        date: "2024-10-02T00:00:00.000Z",
        cost: 200000,
        energy: 110.3,
        device_id: "SP21e831cd6a47c",
        payment_type: "Debit Card"
    },
    {
        id: "5",
        date: "2024-09-01T00:00:00.000Z",
        cost: 1000000,
        energy: 125.6,
        device_id: "SP21e831cd6a47c",
        payment_type: "Cash"
    }
];
